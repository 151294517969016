import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import logo from '@/../svg/letter.svg';
import colors from 'tailwindcss/colors';
import { computed } from 'vue';
import { wTrans as $t } from 'laravel-vue-i18n';

const myTheme = {
    dark: false,
    colors: {
        primary: '#558B2F',
        success: '#689F38',
        info: '#8C2155',
        secondary: '#5085b4',
        alert: '#F28123',
        danger: colors.red['500'],
        red: colors.red['700'],
        black: colors.black,
        surface: colors.white,
        'on-info': colors.white,
        'on-success': colors.white,
        'on-alert': colors.white,
        'on-black': colors.white,
        'on-secondary': colors.white,
        'on-surface': colors.gray['900'],
        'on-surface-variant': colors.gray['50'],
    },
};
const errorTooltip = {
    dark: false,
    colors: {
        'surface-variant': colors.red['500'],
        'on-surface-variant': colors.gray['50'],
    },
};

const defaults = computed(() => ({
    VSelect: {
        noDataText: $t('No data available...').value,
    },
    VAutocomplete: {
        noDataText: $t('No data available...').value,
    },
}));

export default createVuetify({
    theme: {
        defaultTheme: 'myTheme',
        variations: {
            colors: [
                'primary',
                'secondary',
                'info',
                'success',
                'alert',
                'danger',
            ],
            lighten: 2,
            darken: 2,
        },
        themes: {
            myTheme,
            errorTooltip,
        },
    },
    defaults,
    icons: {
        defaultSet: 'mdi',
        sets: {
            mdi,
        },
        aliases: {
            ...aliases,
            logo,
        },
    },
});
